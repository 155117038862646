import { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { usePropertyToLabel } from "../usePropertyToLabel";
import { useLabelUpdate } from "../useLabelUpdate";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import { Items } from "./items";
import { useModalHelper } from "../../hooks/useModalHelper";
import { getWorkflowResources } from "../../api/workflowDefinition";

export default function Properties({ initData, actions }) {
  const [data, setData] = useState()
  const requiredFieldRef = useRef()
  const readOnlyRef = useRef()
  const [workflowResourceOptions, setWorkflowResourceOptions] = useState([]);
  const modalHelper = useModalHelper()
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  useEffect(() => {
    setData(_.cloneDeep(initData))
    loadWorkflowResources();
  }, [])

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)

    setData(data => ({
      ...data
    }));
  }

  const loadWorkflowResources = async () => {
    const workflowResources = await getWorkflowResources();
    setWorkflowResourceOptions([{ value: "", name: "None" }, ...workflowResources.map(({ id, name }) => ({ value: id, name }))])
  }

  const onMatrixChange = (values) => {
    const matrixData = {
      ...data.matrix,
      options: values.options,
      questions: values.questions
    };

    handleOnChange({
      target: {
        name: "matrix",
        value: matrixData,
      }
    })
  }

  return (
    <>
      <div className="popover-container-items">
        <Property.Group title="Information">
          <Property.Label
            label="Type"
            value="Matrix"
          />

          <Property.Text
            onChange={propertyToLabel.onChange}
            label={"Property"}
            name={"property"}
            value={data?.property}
          />

          <Property.ButtonWithLabel
            label="Items"
            text="Edit items"
            onClick={modalHelper.open}
          />

          <Property.RequiredWithCustomMessage
            onChange={handleOnChange}
            data={data}
            ref={requiredFieldRef}
            dependency={readOnlyRef}
          />

          <Property.Checkbox
            onChange={handleOnChange}
            label={"Read only"}
            name={"readOnly"}
            value={data?.readOnly}
            ref={readOnlyRef}
            dependency={requiredFieldRef}
          />

          <Property.Checkbox
            onChange={handleOnChange}
            label={"Show row number"}
            name={"matrix.showRowNumber"}
            value={data?.matrix?.showRowNumber}
          />

        </Property.Group>
        <Property.Group title='Api Retrieve'>

          {/* <Property.Select
            onChange={handleOnChange}
            label={"Api Retrieve"}
            name={"apiRetrieveId"}
            value={data?.apiRetrieveId}
            options={apiDefinitionOptions}
          /> */}

          <Property.Select
            onChange={handleOnChange}
            label={"Workflow Retrieve"}
            name={"workflowRetrieveId"}
            value={data?.workflowRetrieveId}
            options={workflowResourceOptions}
          />

        </Property.Group>

        <Property.Group
          title='Text Style'>

          <Property.Subgroup
            title="Label"
          >

            <Property.Color
              onChange={handleOnChange}
              label={'Color'}
              name={'labelStyle.color'}
              initColor={data?.labelStyle?.color || '#000'}
            />

            <Property.Text
              onChange={handleOnChange}
              label={'Size'}
              name={'labelStyle.size'}
              type={'number'}
              value={data?.labelStyle?.size}
            />

            <Property.FontStyleAndWeight
              onChange={handleOnChange}
              name={"labelStyle"}
              property={data?.labelStyle}
            />

          </Property.Subgroup>

        </Property.Group>

      </div>
      <Items
        modalHelper={modalHelper}
        matrix={data?.matrix}
        onSave={onMatrixChange}
      />
    </>
  )
}